<template>
  <v-card class="card-wrap px-5 py-4" flat>
    <!-- Start: Created time and user -->
    <div>
      <div class="mb-1">
        <span class="font-weight-bold">Người tạo: </span>
        <span>{{ user.name }}</span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">Ngày tạo: </span>
        <span>{{
          goodsReceipt.created_at
            ? goodsReceipt.created_at.replace(/-/g, "/")
            : getToday()
        }}</span>
      </div>
    </div>
    <!-- End: Created time and user -->

    <v-divider class="my-4"></v-divider>

    <!-- Start: Supplier and Branch -->
    <supplier-and-branch></supplier-and-branch>
    <!-- End: Supplier and Branch -->

    <v-divider class="mt-5 mb-3"></v-divider>

    <!-- Start: Supplier invoice info -->
    <supplier-invoice-info></supplier-invoice-info>
    <!-- End: Supplier invoice info -->

    <v-divider class="my-4"></v-divider>

    <!-- Start: Total and summary -->
    <total-and-summary></total-and-summary>
    <!-- End: Total and summary -->

    <!-- Start: File attachment -->
    <!-- End: File attachment -->

    <!-- Start: Actions -->
    <general-actions
      v-if="goodsReceipt.po_id && goodsReceipt.branch_id"
    ></general-actions>

    <v-card v-else class="mt-4" flat>
      <div class="amber lighten-5 text-body-2 px-3 py-2">
        Bạn cần chọn PO và chi nhánh trước khi muốn lưu nháp hay thực hiện nhập
        hàng.
      </div>
    </v-card>
    <!-- End: Actions -->
  </v-card>
</template>

<script>
import GeneralActions from "./components/GeneralActions";
import SupplierAndBranch from "./components/SupplierAndBranch";
import SupplierInvoiceInfo from "./components/SupplierInvoiceInfo";
import TotalAndSummary from "./components/TotalAndSummary";

export default {
  components: {
    GeneralActions,
    SupplierAndBranch,
    SupplierInvoiceInfo,
    TotalAndSummary
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  created() {
    this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
  },
  methods: {
    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 40px - 20px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 40px - 20px - 24px);
  }
}
.btn-submit {
  font-size: 1.0125rem;
}
</style>
