<template>
  <div>
    <!-- Start: Supplier invoice code -->
    <div class="d-flex align-center justify-space-between">
      <div class="font-weight-bold mr-3">Hóa đơn NCC:</div>
      <div style="max-width: 120px">
        <v-text-field
          v-model="goodsReceipt.code_ncc"
          class="text-field__text-right text-body-1 pt-0 mt-0 mb-2"
          :disabled="goodsReceipt.status === 1 || goodsReceipt.status === -1"
          hide-details
          placeholder="Nhập mã hóa đơn"
        ></v-text-field>
      </div>
    </div>
    <!-- End: Supplier invoice code -->

    <!-- Start: Supplier invoice date -->
    <div class="d-flex flex-row align-center justify-space-between mt-1">
      <div class="font-weight-bold mr-3">Ngày hóa đơn NCC:</div>
      <tp-input-date
        custom-class="text-field__text-right text-body-1 pt-0 mt-0 mb-2"
        custom-style="max-width: 70px"
        :disabled="goodsReceipt.status === 1 || goodsReceipt.status === -1"
        hide-details
        placeholder="Chọn ngày"
        :value="goodsReceipt.date_ncc"
        @change="goodsReceipt.date_ncc = $event"
      ></tp-input-date>
    </div>
    <!-- End: Supplier invoice date -->

    <!-- Start: Tracking date -->
    <div class="d-flex align-center justify-space-between mt-1">
      <div class="font-weight-bold mr-3">Tracking:</div>
      <div style="max-width: 120px">
        <v-text-field
          v-model="goodsReceipt.global_tracking"
          class="text-field__text-right text-body-1 pt-0 mt-0 mb-2"
          :disabled="goodsReceipt.status === 1 || goodsReceipt.status === -1"
          hide-details
          placeholder="Nhập tracking"
        ></v-text-field>
      </div>
    </div>
    <!-- End: Tracking date -->

    <!-- Start: Shipping fee -->
    <div class="d-flex align-center justify-space-between mt-1">
      <div class="font-weight-bold mr-3">Phí nhập hàng:</div>

      <div class="d-flex align-center">
        <div v-if="!isImportFeeVisible">
          {{ goodsReceipt.import_fee | formatCurrency }}
        </div>

        <v-btn
          v-if="!isImportFeeVisible"
          color="primary"
          text
          @click="isImportFeeVisible = true"
          >Cập nhật</v-btn
        >

        <tp-input-price
          v-if="isImportFeeVisible"
          custom-class="text-body-1 price--text-right mt-0 pt-0 mb-2"
          custom-style="max-width: 100px"
          hide-details
          :value="goodsReceipt.import_fee"
          @change="goodsReceipt.import_fee = $event"
        ></tp-input-price>

        <v-btn
          v-if="isImportFeeVisible && goodsReceipt.status == 1"
          class="mb-2"
          color="primary"
          :loading="
            goodsReceiptStatusRequest.value === 'loading-updateImportFee'
          "
          text
          @click="handleConfirmUpdateImportFee"
          >Lưu</v-btn
        >
      </div>
    </div>
    <!-- End: Shipping fee -->

    <!-- Start: Return rebate amount -->
    <div class="mt-4">
      <div class="d-flex justify-space-between font-weight-bold mb-2">
        <div>Trả CKTM ({{ goodsReceipt.returned_rebates.length }})</div>
        <div>{{ goodsReceiptReturnedRebatesTotal | formatCurrency }}</div>
      </div>
      <v-btn
        v-if="goodsReceipt.status !== 1 && goodsReceipt.status !== -1"
        class="text-subtitle-2 text-none font-weight-medium justify-start px-2"
        color="primary"
        depressed
        text
        @click="addEmptyReturnRebate"
        small
      >
        <v-icon class="mr-1" size="16px">mdi-plus</v-icon>
        Thêm khoản trả CKTM
      </v-btn>
      <div class="return-rebates">
        <card-return-rebate
          class="mt-2"
          v-for="(rebate, index) in goodsReceipt.returned_rebates"
          :key="index"
          :return-rebate="rebate"
          :return-rebate-index="index"
        ></card-return-rebate>
      </div>
    </div>
    <!-- End: Return rebate amount -->
  </div>
</template>

<script>
import CardReturnRebate from "./CardReturnRebate";

export default {
  components: {
    CardReturnRebate
  },

  data() {
    return {
      isImportFeeVisible: true
    };
  },

  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },

  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },

    goodsReceiptStatusRequest() {
      return this.$store.getters["GOODS_RECEIPT/statusRequest"];
    },

    goodsReceiptReturnedRebatesTotal() {
      return this.goodsReceipt.returned_rebates.reduce(
        (total, item) => total + item.value,
        0
      );
    }
  },

  methods: {
    addEmptyReturnRebate() {
      this.goodsReceipt.returned_rebates.push({
        code: null,
        left: 0,
        value: null
      });
    },

    async handleConfirmUpdateImportFee() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "<span>Xác nhận cập nhật Phí nhập hàng</span>",
          message:
            "Sau khi cập nhật phí nhập hàng, giá vốn các sản phẩm trong đơn sẽ được thay đổi.",
          confirmBtn: {
            color: "primary accent-2",
            text: "Xác nhận",
            onClickHandler: async () => {
              await this.$store.dispatch("GOODS_RECEIPT/updateImportFee", {
                id: this.goodsReceipt.id,
                import_fee: this.goodsReceipt.import_fee
              });

              // Alert
              if (
                this.goodsReceiptStatusRequest.value ===
                "success-updateImportFee"
              ) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Cập nhật thành công phí nhập hàng"
                  }
                });

                await this.$store.dispatch(
                  "GOODS_RECEIPT/getGoodsReceiptById",
                  this.goodsReceipt.id
                );

                this.isImportFeeVisible = false;
              } else if (
                this.goodsReceiptStatusRequest.value === "error-updateImportFee"
              ) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: this.goodsReceiptStatusRequest.message
                  }
                });
              }
            }
          }
        }
      });
    }
  },

  mounted() {
    if (this.goodsReceipt.status === 1 || this.goodsReceipt.status === -1) {
      this.isImportFeeVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.return-rebates--add {
  border: 1px dashed #e5e5e5 !important;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    background-color: #fafafa;
  }
}
</style>
