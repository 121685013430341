<template>
  <div>
    <!-- Start: Total -->
    <div class="d-flex flex-row align-center justify-space-between mt-2">
      <div class="font-weight-bold">Giá trị hóa đơn</div>
      <div class="text-h6 red--text text--accent-2">
        {{ goodsReceiptValue | formatCurrency }}
      </div>
    </div>
    <!-- End: Total -->

    <!-- Start: Expiration date -->
    <div class="d-flex flex-row align-center justify-space-between mt-4">
      <div class="font-weight-bold">Hạn thanh toán</div>
      <div>
        {{
          goodsReceipt.po
            ? `${goodsReceipt.po.pay_deadline} ngày (${payDeadlineDate})`
            : "N/A"
        }}
      </div>
    </div>
    <!-- End: Expiration date -->

    <!-- Start: Rebate -->
    <div class="mt-4">
      <div class="d-flex justify-space-between font-weight-bold mb-2">
        <div>CKTM phát sinh ({{ goodsReceipt.generated_rebates.length }})</div>
        <div>{{ goodsReceiptGeneratedRebatesTotal | formatCurrency }}</div>
      </div>
      <div
        class="rebate-list grey lighten-4 rounded pa-2"
        v-if="goodsReceipt.generated_rebates.length > 0"
      >
        <div
          class="white rebate-list--item rounded px-3 py-2 mb-2"
          v-for="(item, index) in goodsReceipt.generated_rebates"
          :key="index"
        >
          <div class="d-flex flex-wrap flex-md-nowrap justify-md-space-between">
            <div>{{ item.code }}</div>
            <div class="font-weight-medium">
              {{ item.total | formatCurrency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: Rebate -->
  </div>
</template>

<script>
export default {
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },

    goodsReceiptGeneratedRebatesTotal() {
      return this.goodsReceipt.generated_rebates.reduce(
        (total, item) => total + item.total,
        0
      );
    },

    goodsReceiptOptions() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptions"];
    },

    goodsReceiptValue() {
      return this.goodsReceiptOptions.reduce(
        (total, item) =>
          total +
          item.po_option_branch.option.import_price * item.serials.length,
        0
      );
    },

    payDeadlineDate() {
      if (this.goodsReceipt.date_ncc) {
        let date = new Date(this.goodsReceipt.date_ncc);

        date.setDate(date.getDate() + this.goodsReceipt.po.pay_deadline);

        return this.formatDate(date);
      }
      return "N/A";
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const dateTime = new Date(date),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${day}/${month}/${year}`;
    }
  }
};
</script>
