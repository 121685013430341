<template>
  <div>
    <div class="">
      <div class="font-weight-bold mb-2">Đơn đặt NCC (P/0)</div>
      <div class="d_flex align-center px-0">
        <v-text-field
          v-model="purchaseOrderCode"
          class="text-body-1 mr-3"
          clearable
          dense
          :disabled="
            goodsReceipt.id &&
              $route.name === 'transaction_goods-receipts_notes-detail'
          "
          :error="
            purchaseOrderStatusRequest.value === 'error-getPurchaseOrderByCode'
          "
          :error-messages="purchaseOrderStatusRequest.message"
          flat
          hide-details="auto"
          placeholder="Nhập mã P/O"
          solo
          outlined
          @keypress.enter="getPurchaseOrderByCode"
        ></v-text-field>
      </div>
    </div>

    <div class="mt-4">
      <div class="font-weight-bold mb-2">Chi nhánh</div>
      <div class="d_flex align-center px-0">
        <v-select
          v-model="goodsReceipt.branch_id"
          class="text-body-1 mr-3"
          clearable
          dense
          :disabled="
            (goodsReceipt.id &&
              $route.name === 'transaction_goods-receipts_notes-detail') ||
              !goodsReceipt.po_id
          "
          flat
          hide-details
          :items="purchaseOrderBranches"
          item-text="name"
          item-value="id"
          :menu-props="{ nudgeTop: '-40px' }"
          no-data-text="Không có dữ liệu"
          :placeholder="
            goodsReceipt.po_id ? 'Chọn chi nhánh' : 'Nhập P/O trước'
          "
          solo
          outlined
          @change="selectBranch($event)"
        ></v-select>
      </div>
    </div>

    <div class="mt-4" v-if="goodsReceipt.po">
      <div class="font-weight-bold mb-2">
        Nhà cung cấp ({{ goodsReceipt.po.supplier.code }})
      </div>
      <v-card class="grey lighten-4 pa-4 py-3" flat>
        <div class="d-flex flex-row align-center justify-space-between">
          <div class="mr-3">Công nợ</div>
          <div class="font-weight-bold">
            {{ goodsReceipt.po.supplier.cong_no | formatCurrency }}
          </div>
        </div>
        <div class="d-flex flex-row align-center justify-space-between mt-2">
          <div class="mr-3">Hạn mức còn lại</div>
          <div class="font-weight-bold">
            {{ "N/A" }}
          </div>
        </div>
        <div class="d-flex flex-row justify-space-between mt-2">
          <div class="mr-3">
            <div>Thanh toán gần nhất</div>
            <div class="text-subtitle-2 font-weight-light">(5 ngày trước)</div>
          </div>
          <div class="font-weight-bold">
            {{ "N/A" }}
          </div>
        </div>
        <div class="d-flex flex-row align-center justify-space-between mt-2">
          <div class="font-weight-medium mr-3">Tài khoản chiết khấu</div>
          <div class="font-weight-bold">
            {{ "N/A" }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      purchaseOrderCode: null
    };
  },
  computed: {
    rolesName() {
      return this.$store.getters["ROLE/rolesName"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    goodsReceiptStatusRequest() {
      return this.$store.getters["GOODS_RECEIPT/statusRequest"];
    },
    purchaseOrder() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrder"];
    },
    purchaseOrderBranches() {
      let branches = [];

      if (this.goodsReceipt.po && this.goodsReceipt.po.options) {
        this.goodsReceipt.po.options.forEach(option => {
          if (option.assignments.length > 0) {
            const arr = option.assignments.map(item => {
              return {
                id: item.branch_id,
                name: item.branch_name
              };
            });
            branches = branches.concat([...arr]);
          }
        });
      }

      return branches;
    },
    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    }
  },
  watch: {
    goodsReceipt(val) {
      if (val.po) {
        this.purchaseOrderCode = val.po.code;
      }
    },
    purchaseOrderCode(val) {
      if (!val || val.trim() === "") {
        this.goodsReceipt.po = null;
      }
    }
  },
  created() {
    if (this.goodsReceipt.po) {
      this.purchaseOrderCode = this.goodsReceipt.po.code;
    }
    if (this.employees && this.employees.length === 0)
      this.$store.dispatch("EMPLOYEE/getAllEmployees");
  },
  methods: {
    getNameRole(role) {
      return this.rolesName.filter(item => item.value === role)[0]["text"];
    },
    async getPurchaseOrderByCode() {
      // Send request
      await this.$store.dispatch(
        "PURCHASE_ORDER/getPurchaseOrderByCode",
        this.purchaseOrderCode
      );
      // Check request
      if (
        this.purchaseOrderStatusRequest.value ===
        "success-getPurchaseOrderByCode"
      ) {
        this.goodsReceipt.po_id = this.purchaseOrder.id;
        this.goodsReceipt.po = JSON.parse(JSON.stringify(this.purchaseOrder));
      }
    },

    showModalAddRoleServer() {
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setBranchIDModelAddRoleServer",
        this.offBizDetail && this.offBizDetail.branch_id
          ? this.offBizDetail.branch_id
          : null
      );
      this.$modal.show({
        name: "modal-add-role-server",
        payload: {
          model_id:
            this.goodsReceipt && this.goodsReceipt.id
              ? this.goodsReceipt.id
              : null,
          model_name: "App\\Models\\HoaDonNhap",
          dataNamespaced: "GOODS_RECEIPT/getGoodsReceiptById",
          dataRequest: this.goodsReceipt.id
        }
      });
    },
    async selectBranch(val) {
      if (val) {
        if (
          this.$route.name === "transaction_goods-receipts_notes-create" &&
          this.$route.query.id
        ) {
          await this.$store.dispatch(
            "GOODS_RECEIPT/forceDeleteGoodsReceipt",
            this.goodsReceipt.id
          );
        }

        await this.$store.dispatch(
          "GOODS_RECEIPT/createGoodsReceipt",
          this.goodsReceipt
        );

        if (
          this.goodsReceiptStatusRequest.value === "success-createGoodsReceipt"
        ) {
          await this.$router.replace({
            name: "transaction_goods-receipts_notes-create",
            query: {
              id: this.goodsReceipt.id
            }
          });
        }
      }
    }
  }
};
</script>
