<template>
  <v-row no-gutters>
    <v-col cols="5">
      <div class="d-flex align-center font-weight-bold pt-2">
        <v-icon class="pr-3">mdi-account</v-icon>
        <span>Người thực hiện</span>
      </div>
    </v-col>
    <v-col cols="7">
      <tp-autocomplete
        v-model="invoiceParticipant.user_id"
        :items="employees"
        item-text="name"
        item-value="id"
        placeholder="Chọn người thực hiện"
        validate="required"
      ></tp-autocomplete>
    </v-col>

    <v-col cols="5">
      <div class="d-flex align-center font-weight-bold pt-2">
        <v-icon class="pr-3">mdi-briefcase-variant-outline</v-icon>
        <span>Nghiệp vụ</span>
      </div>
    </v-col>
    <v-col cols="7">
      <tp-select
        v-model="invoiceParticipant.role"
        :items="invoiceRoles"
        item-text="text"
        item-value="value"
        placeholder="Chọn nghiệp vụ"
        validate="required"
      ></tp-select>
    </v-col>

    <v-col cols="5">
      <div class="d-flex align-center font-weight-bold pt-2">
        <v-icon class="pr-3">mdi-text</v-icon>
        <span>{{
          invoiceParticipant.role === "SHIPPING" ? "Quãng đường" : "Số lượng"
        }}</span>
      </div>
    </v-col>
    <v-col cols="7">
      <tp-text-field
        v-model.number="invoiceParticipant.value"
        placeholder="Nhập giá trị"
        :suffix="invoiceParticipant.role === 'SHIPPING' ? 'km' : 'pcs'"
        type="number"
        validate="required"
      ></tp-text-field>
    </v-col>

    <v-col cols="5">
      <div class="d-flex align-center font-weight-bold pt-2">
        <v-icon class="pr-3">mdi-square-edit-outline</v-icon>
        <span>Ghi chú</span>
      </div>
    </v-col>
    <v-col cols="7">
      <tp-textarea
        v-model="invoiceParticipant.note"
        class="pt-2"
        placeholder="Ghi chú"
      ></tp-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    invoiceParticipant() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipant"];
    },

    invoiceRoles() {
      return this.$store.getters["ROLE/rolesName"];
    }
  }
};
</script>
