<template>
  <div class="mt-5">
    <v-btn
      v-if="goodsReceipt.status !== 1"
      block
      class="btn-submit"
      color="primary"
      dark
      depressed
      large
      :loading="
        goodsReceiptStatusRequest.value === 'loading-updateGoodsReceipt'
      "
      @click="updateGoodsReceipt(0)"
    >
      Lưu nháp
    </v-btn>
    <v-btn
      v-if="goodsReceipt.status !== 1"
      block
      class="btn-submit mt-2"
      color="primary"
      dark
      depressed
      large
      :loading="
        goodsReceiptStatusRequest.value === 'loading-updateGoodsReceipt'
      "
      @click="updateGoodsReceipt(1)"
    >
      Nhập hàng
    </v-btn>
    <v-btn
      v-if="
        goodsReceipt.id &&
          $route.name === 'transaction_goods-receipts_notes-detail'
      "
      block
      class="btn-submit mt-2 grey--text text--darken-2"
      color="grey lighten-2"
      depressed
      large
      :loading="
        goodsReceiptStatusRequest.value === 'loading-cancelGoodsReceipt'
      "
      @click="cancelGoodsReceipt"
    >
      Hủy phiếu
    </v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    goodsReceiptOptions() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptions"];
    },
    goodsReceiptGifts() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptGifts"];
    },
    goodsReceiptStatusRequest() {
      return this.$store.getters["GOODS_RECEIPT/statusRequest"];
    }
  },
  methods: {
    async cancelGoodsReceipt() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy phiếu nhập <strong>NH${this.goodsReceipt.id}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          cancelBtnText: "Bỏ qua",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục hủy",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "GOODS_RECEIPT/cancelGoodsReceipt",
                this.goodsReceipt.id
              );
              if (
                this.goodsReceiptStatusRequest.value ===
                "success-cancelGoodsReceipt"
              ) {
                await this.$router.push({
                  name: "transaction_goods-receipts_notes",
                  query: {
                    limit: 50,
                    page: 1
                  }
                });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy phiếu nhập"
                  }
                });
              }
            }
          }
        }
      });
    },

    async updateGoodsReceipt(action) {
      if (action === 1) {
        this.goodsReceipt.is_import = 1;
      }
      const objectSender = JSON.parse(JSON.stringify(this.goodsReceipt));

      // Handle sender serials array
      const optionSerials = await this.goodsReceiptOptions.map(item => {
        return {
          hdn_po_option_branch_id: item.id,
          serials: item.serials
        };
      });
      const giftSerials = await this.goodsReceiptGifts.map(item => {
        return {
          hdn_po_option_branch_id: item.id,
          serials: item.serials
        };
      });

      objectSender.po_option_branch_serials = [
        ...optionSerials,
        ...giftSerials
      ];

      // Handle sender returned rebates array
      objectSender.returned_rebates = objectSender.returned_rebates.map(
        item => {
          return {
            hdn_generated_rebate_id: item.id,
            ...item
          };
        }
      );

      // Request update
      await this.$store.dispatch(
        "GOODS_RECEIPT/updateGoodsReceipt",
        objectSender
      );
      // Alert
      if (
        this.goodsReceiptStatusRequest.value === "success-updateGoodsReceipt"
      ) {
        if (this.$route.name === "transaction_goods-receipts_notes-create") {
          await this.$router.replace({
            name: "transaction_goods-receipts_notes-detail",
            params: {
              goodsReceiptId: this.goodsReceipt.id
            }
          });
        }

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: action ? "Đã nhập thành công" : "Đã lưu nháp phiếu nhập"
          }
        });
      } else if (
        this.goodsReceiptStatusRequest.value === "error-updateGoodsReceipt"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.goodsReceiptStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
