<template>
  <v-card class="grey lighten-4 pt-2 pb-5 px-4" flat>
    <v-row no-gutters>
      <v-col class="pr-2" cols="4">
        <v-select
          v-model="rebateType"
          class="text-body-1"
          flat
          dense
          disabled
          :items="rebateTypeList"
          item-text="text"
          item-value="id"
          :menu-props="{ nudgeTop: '-28px' }"
          placeholder="Đối tác"
          hide-details="auto"
          single-line
        ></v-select>
      </v-col>
      <v-col class="pl-2" cols="8">
        <v-autocomplete
          v-if="rebateType === 2"
          v-model="rebate.brand_id"
          class="text-body-1"
          dense
          disabled
          flat
          :items="brands"
          item-text="name"
          item-value="id"
          hide-details
          no-data-text="Không có dữ liệu"
          placeholder="Chọn hãng"
        ></v-autocomplete>
        <v-autocomplete
          v-else
          v-model="rebate.supplier_id"
          class="text-body-1"
          dense
          disabled
          flat
          :items="suppliers"
          item-text="name"
          item-value="id"
          hide-details
          no-data-text="Không có dữ liệu"
          placeholder="Chọn nhà cung cấp"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-3" no-gutters>
      <v-col class="pr-2" cols="4">
        <tp-input-price
          custom-class="text-body-1 pt-0"
          dense
          disabled
          flat
          hide-details
          suffix="₫/sp"
          :value="rebate.value"
        ></tp-input-price>
      </v-col>
      <v-col class="pl-2" cols="8">
        <v-text-field
          v-model="rebate.note"
          class="text-body-1"
          disabled
          flat
          dense
          placeholder="Ghi chú"
          hide-details="auto"
          readonly
          single-line
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    rebate: {
      type: Object
    }
  },
  data() {
    return {
      rebateType: 1,
      rebateTypeList: [
        { id: 1, text: "Nhà cung cấp" },
        { id: 2, text: "Hãng" }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    }
  },
  async created() {
    await this.$store.dispatch("BRAND/getAllBrands");
    await this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");

    if (this.rebate.brand_id) {
      this.rebateType = 2;
    } else {
      this.rebateType = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-custom {
  ::v-deep .v-badge__wrapper {
    z-index: 9;
  }
}
.btn-rebate--remove {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
