<template>
  <div>
    <div class="d-flex align-center mb-3">
      <div class="text-h6 font-weight-bold mr-4 mb-1">Quà tặng kèm</div>
      <v-radio-group
        v-model="goodsReceiptOption.po_option_branch.option.has_gift"
        class="mt-0 pt-0"
        dense
        disabled
        hide-details="auto"
        row
      >
        <v-radio label="Không" :value="0"></v-radio>
        <v-radio label="Có" :value="1"></v-radio>
      </v-radio-group>
    </div>

    <v-row class="px-1">
      <v-col
        class="py-1 px-2"
        cols="12"
        lg="6"
        xl="4"
        v-for="(item, index) in goodsReceiptOptionGifts"
        :key="index"
      >
        <card-gift :gift="item"></card-gift>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardGift from "./CardGift";

export default {
  components: {
    CardGift
  },
  computed: {
    goodsReceiptGifts() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptGifts"];
    },
    goodsReceiptOption() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOption"];
    },
    goodsReceiptOptionGifts() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptionGifts"];
    }
  }
};
</script>

<style lang="scss" scoped>
.search-option--box {
  @media (min-width: 1264px) {
    padding-right: 8px;
    width: 50%;
  }
  width: 100%;
  @media (min-width: 1904px) {
    padding-right: 8px;
    width: 33.333%;
  }
}
</style>
