<template>
  <v-row>
    <v-col class="py-0" cols="8">
      <products-detail></products-detail>
    </v-col>

    <v-col class="scrollable overflow-auto py-0" cols="4">
      <general></general>
    </v-col>
  </v-row>
</template>

<script>
import General from "./components/General/index";
import ProductsDetail from "./components/ProductsDetail";

export default {
  components: {
    General,
    ProductsDetail
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 40px - 20px - 24px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 40px - 20px - 24px);
  }
}
</style>
