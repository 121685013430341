var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"top-bar"},[_c('v-tabs',{staticClass:"rounded mb-5",attrs:{"background-color":"white","slider-size":"0","height":"40px"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab',{key:item.id,staticClass:"tab-custom text-body-1 font-weight-medium text-none",staticStyle:{"letter-spacing":"0"},attrs:{"active-class":"primary--text"}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1),(
        _vm.goodsReceipt &&
          _vm.goodsReceipt.status !== null &&
          _vm.goodsReceipt.status !== undefined
      )?_c('div',{staticClass:"top-bar--status"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.goodsReceipt.status === 1
            ? 'green'
            : _vm.goodsReceipt.status === -1
            ? 'red accent-2'
            : 'grey',"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.goodsReceipt.status === 1 ? "Đã nhập" : _vm.goodsReceipt.status === -1 ? "Bị hủy" : "Nháp")+" ")])],1):_vm._e()],1),_c('v-tabs-items',{staticClass:"grey lighten-3",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',{key:"info",staticClass:"tab-item"},[_c('tab-info')],1),_c('v-tab-item',{key:"participants",staticClass:"tab-item"},[_c('tab-participants')],1),_c('v-tab-item',{key:"discussion",staticClass:"tab-item"},[_c('tab-discussion')],1),_c('v-tab-item',{key:"history",staticClass:"tab-item"},[_c('tab-payment-history')],1)],1),_c('modal-goods-receipt-note-product-option')],1)}
var staticRenderFns = []

export { render, staticRenderFns }