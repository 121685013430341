<template>
  <tp-modal fullscreen name="modal-goods-receipt-note-product-options">
    <v-card class="grey lighten-3" flat>
      <!-- Start: Modal toolbar -->
      <modal-toolbar></modal-toolbar>
      <!-- End: Modal toolbar -->
      <!-- Start: Option list -->
      <div class="mx-5">
        <div class="options-list py-5 pr-3">
          <v-chip
            class="options-list--item option-chip font-weight-medium white rounded-lg px-4 py-2"
            :class="{
              'cyan lighten-4': goodsReceiptOptionActiveIndex === index
            }"
            v-for="(item, index) in goodsReceiptOptions"
            :key="index"
            @click="changeActiveOption(item, index)"
          >
            {{ item.po_option_branch.option.option.SKU }}
          </v-chip>
        </div>
      </div>
      <!-- End: Option list -->
      <!-- Start: Modal body -->
      <v-row class="mx-0 mb-5">
        <!-- Start: SKU info -->
        <v-col class="px-5 py-0" cols="5">
          <div class="col-scrollable">
            <col-info></col-info>
          </div>
        </v-col>
        <!-- End: SKU info -->
        <!-- Start: Product info -->
        <v-col class="pl-0 pr-5 py-0" cols="7">
          <div class="col-scrollable">
            <col-form></col-form>
          </div>
        </v-col>
        <!-- Start: Product info -->
      </v-row>
      <!-- End: Modal body -->
    </v-card>
  </tp-modal>
</template>

<script>
import ColForm from "./components/ColForm/index";
import ColInfo from "./components/ColInfo/index";
import ModalToolbar from "./components/ModalToolbar";

export default {
  components: {
    ColForm,
    ColInfo,
    ModalToolbar
  },
  data() {
    return {};
  },
  computed: {
    goodsReceiptOptionActiveIndex() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptionActiveIndex"];
    },
    goodsReceiptOptions() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptions"];
    }
  },
  methods: {
    async changeActiveOption(item, index) {
      // Set option
      await this.$store.dispatch("GOODS_RECEIPT/setGoodsReceiptOption", item);
      // Set option index
      await this.$store.dispatch(
        "GOODS_RECEIPT/setGoodsReceiptOptionActiveIndex",
        index
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.options {
  &-list {
    display: flex;
    max-width: 100%;
    overflow: scroll;
    &--item {
      cursor: pointer;
      margin-left: 12px;
      &:first-child {
        margin-left: 0 !important;
      }
      .option-chip {
        cursor: pointer;
        height: 36px;
      }
    }
  }
}
.col-scrollable {
  max-height: calc(100vh - 56px - 76px - 20px);
  overflow-y: scroll;
}
</style>
