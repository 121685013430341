<template>
  <tp-modal name="modal-form-participant" max-width="560px">
    <v-card class="text-body-1" flat>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          {{
            invoiceParticipant.id
              ? "Sửa ghi nhận phục vụ"
              : "Thêm ghi nhận phục vụ"
          }}
        </div>

        <v-spacer></v-spacer>

        <v-btn
          v-if="!invoiceParticipant.id"
          class="mr-2"
          color="primary"
          depressed
          :disabled="
            invoiceParticipantStatusRequest.value ===
              'loading-createInvoiceParticipant'
          "
          :loading="
            invoiceParticipantStatusRequest.value ===
              'loading-createInvoiceParticipant'
          "
          @click="createInvoiceParticipant"
          >Thêm</v-btn
        >

        <v-btn
          v-else
          class="mr-2"
          color="primary"
          depressed
          :disabled="
            invoiceParticipantStatusRequest.value ===
              'loading-updateInvoiceParticipant'
          "
          :loading="
            invoiceParticipantStatusRequest.value ===
              'loading-updateInvoiceParticipant'
          "
          @click="updateInvoiceParticipant"
          >Lưu</v-btn
        >

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" lazy-validation>
        <v-container class="grey lighten-3 pa-5" fluid>
          <v-card class="px-4 py-5" flat>
            <form-general />
            <form-gallery />
          </v-card>
        </v-container>
      </v-form>
    </v-card>
  </tp-modal>
</template>
<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import FormGeneral from "@/core/components/modals/ModalFormParticipant/FormGeneral.vue";
import FormGallery from "@/core/components/modals/ModalFormParticipant/FormGallery.vue";

export default {
  components: { FormGallery, FormGeneral },

  props: {
    modelId: {
      type: Number,
      default: null
    },
    modelName: {
      type: String,
      default: null
    }
  },

  computed: {
    invoiceParticipant() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipant"];
    },

    invoiceParticipantStatusRequest() {
      return this.$store.getters["INVOICE_PARTICIPANT/statusRequest"];
    }
  },

  methods: {
    closeModal() {
      this.$refs.form.resetValidation();

      this.$modal.hide({ name: "modal-form-participant" });
    },

    async createInvoiceParticipant() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      // Set model
      this.invoiceParticipant.model_name = this.modelName;
      this.invoiceParticipant.model_id = this.modelId;

      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/createInvoiceParticipant",
        { ...this.invoiceParticipant }
      );

      if (
        this.invoiceParticipantStatusRequest.value ===
        "success-createInvoiceParticipant"
      ) {
        showToastActionAlert(this, { message: "Thêm mới phục vụ thành công" });

        this.$emit("onSuccessfullyCreate");

        this.closeModal();
      } else if (
        this.invoiceParticipantStatusRequest.value ===
        "error-createInvoiceParticipant"
      ) {
        showModalAlertError(this, {
          title: this.invoiceParticipantStatusRequest.message
        });
      }
    },

    async updateInvoiceParticipant() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/updateInvoiceParticipant",
        { ...this.invoiceParticipant }
      );

      if (
        this.invoiceParticipantStatusRequest.value ===
        "success-updateInvoiceParticipant"
      ) {
        showToastActionAlert(this, { message: "Cập nhật phục vụ thành công" });

        this.$emit("onSuccessfullyUpdate");

        this.closeModal();
      } else if (
        this.invoiceParticipantStatusRequest.value ===
        "error-updateInvoiceParticipant"
      ) {
        showModalAlertError(this, {
          title: this.invoiceParticipantStatusRequest.message
        });
      }
    }
  }
};
</script>
