<template>
  <div>
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Chiết khấu ({{ goodsReceiptOptionRebateTotal | formatCurrency }}/sp)
      </div>
    </div>
    <v-row class="px-1">
      <v-col
        class="py-1 px-2"
        cols="12"
        xl="6"
        v-for="(item, index) in goodsReceiptOption.po_option_branch.option
          .rebates"
        :key="index"
      >
        <card-rebate :rebate="item"></card-rebate>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardRebate from "./CardRebate";

export default {
  components: {
    CardRebate
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    goodsReceiptOption() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOption"];
    },
    goodsReceiptOptionRebateTotal() {
      const rebates = this.goodsReceiptOption.po_option_branch.option.rebates;

      if (rebates.length > 0) {
        let total = 0;

        rebates.forEach(item => {
          if (item.value) {
            total += parseInt(item.value);
          }
        });

        return total;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-rebate--add {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
