<template>
  <v-card elevation="0" class="pa-6 service-data">
    <div class="service-data__header font-weight-black text-h5">
      Dịch vụ đi kèm
    </div>
    <v-data-table
      class="service-data__table rounded-0 pt-5"
      disable-pagination
      disable-sort
      :headers="headers"
      :loading="
        goodsReceiptStatusRequest.value === 'loading-getGoodsReceiptById'
      "
      :items="goodsReceipt.participants"
      no-data-text="Không có dữ liệu"
      hide-default-footer
    >
      <template #[`item.user`]="{ item }">
        {{ item.user ? item.user.name : "N/A" }}
      </template>

      <template #[`item.role`]="{ item }">
        {{ getNameOfRole(item.role) }}
      </template>

      <template #[`item.docs`]="{ item }">
        <div v-if="item.docs" class="d-flex align-center">
          <div
            v-for="image in item.docs.slice(0, 2)"
            :key="image"
            class="img-wrapper mr-1"
          >
            <v-dialog max-width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  class="grey darken-3 rounded"
                  :aspect-ratio="1"
                  contain
                  :src="`${baseFileUrl}/${image}`"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <div class="white pa-8" style="height: 70vh">
                <v-img
                  contain
                  :src="`${baseFileUrl}/${image}`"
                  height="100%"
                  max-height="70vh"
                ></v-img>
              </div>
            </v-dialog>
          </div>
          <div v-if="item.docs.length > 2" class="primary--text">+2</div>
        </div>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.status`]="{ item }" class="text-center">
        <v-chip
          class="font-weight-bold"
          :color="
            item.status === 1
              ? 'green'
              : item.status === 0
              ? 'orange'
              : 'red accent-2'
          "
          small
          outlined
        >
          {{
            item.status === 1
              ? "Đã duyệt"
              : item.status === 0
              ? "Chờ tài liệu"
              : "Bị từ chối"
          }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn
            fab
            text
            depressed
            x-small
            class=""
            @click="openModalFormParticipant(item)"
          >
            <v-icon color="primary" size="24px">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn v-if="false" fab text depressed x-small>
            <v-icon color="red darken-3">mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-btn
      class="mt-6"
      color="primary"
      dark
      depressed
      @click="openModalFormParticipant"
      >Thêm ghi nhận phục vụ</v-btn
    >
    <modal-form-participant
      :model-id="goodsReceipt.id"
      :model-name="'App\\Models\\HoaDonNhap'"
      @onSuccessfullyCreate="getParticipant"
      @onSuccessfullyUpdate="getParticipant"
    ></modal-form-participant>
  </v-card>
</template>
<script>
import ModalFormParticipant from "@/core/components/modals/ModalFormParticipant/index";

export default {
  components: {
    ModalFormParticipant
  },

  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },

    goodsReceiptStatusRequest() {
      return this.$store.getters["GOODS_RECEIPT/statusRequest"];
    },

    rolesName() {
      return this.$store.getters["ROLE/rolesName"];
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        {
          text: "Người thực hiện",
          value: "user"
        },
        {
          text: "Nghiệp vụ",
          value: "role"
        },
        {
          text: "Giá trị",
          value: "value"
        },
        {
          text: "Ghi chú",
          value: "note"
        },
        {
          text: "Tài liệu",
          value: "docs"
        },
        {
          text: "Trạng thái",
          value: "status"
        },
        {
          text: "",
          align: "right",
          value: "actions"
        }
      ]
    };
  },
  methods: {
    getNameOfRole(role) {
      return this.rolesName.filter(item => item.value === role)[0]["text"];
    },

    async getParticipant() {
      await this.$store.dispatch(
        "GOODS_RECEIPT/getGoodsReceiptById",
        this.goodsReceipt.id
      );
    },

    openModalFormParticipant(item) {
      if (item.id) {
        this.$store.dispatch("INVOICE_PARTICIPANT/setInvoiceParticipant", {
          ...item
        });
      } else {
        this.$store.dispatch("INVOICE_PARTICIPANT/resetInvoiceParticipant");
      }

      this.$modal.show({ name: "modal-form-participant" });
    }
  }
};
</script>
<style lang=""></style>
