<template>
  <v-toolbar class="modal-toolbar rounded-0" dark color="primary" height="56px">
    <v-btn icon dark @click="closeModal">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-toolbar-title class="text-h6 font-weight-bold pl-0 ml-1">
      Sản phẩm nhập
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items
      v-if="goodsReceipt.status !== 1 && goodsReceipt.status !== -1"
    >
      <v-btn dark text :disabled="false" @click="onSave">
        Lưu
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    goodsReceiptOptions() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptions"];
    },
    goodsReceiptGifts() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptGifts"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-goods-receipt-note-product-options" });
    },
    async onSave() {
      const rebates = [];

      await this.goodsReceiptOptions.forEach(item => {
        if (
          item.serials.length > 0 &&
          item.po_option_branch.option.rebates.length > 0
        ) {
          item.po_option_branch.option.rebates.forEach(rebate => {
            rebates.push({
              po_option_branch_id: item.po_option_branch_id,
              code: `CKTM-XXXX-XXXX`,
              total: rebate.value * item.serials.length
            });
          });
        }
      });

      this.goodsReceipt.generated_rebates = rebates;
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-toolbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
</style>
