<template>
  <div>
    <v-card class="px-5 pt-4 pb-5" flat>
      <div class="text-h6 font-weight-bold">Thông tin nhập hàng</div>
      <v-row>
        <v-col cols="12">
          <div class="font-weight-bold mb-2">Giá nhập</div>
          <div class="d-flex align-center">
            <div class="mr-4" style="width: 150px">
              <tp-input-price
                custom-class="text-body-1 mt-0 pt-0"
                dense
                disabled
                flat
                hide-details
                outlined
                solo
                :value="goodsReceiptOption.po_option_branch.option.import_price"
              ></tp-input-price>
            </div>
            <v-radio-group
              v-model="goodsReceiptOption.po_option_branch.option.has_vat"
              class="mt-0 pt-0"
              dense
              disabled
              hide-details="auto"
              row
            >
              <v-radio label="VAT" :value="1"></v-radio>
              <v-radio label="Không VAT" :value="0"></v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>

      <list-rebates class="mt-2"></list-rebates>

      <v-row class="mt-1">
        <v-col cols="12" lg="4" xl="3">
          <div class="font-weight-bold mb-2">Giá cuối</div>
          <div class="d-flex align-center">
            <div class="mr-4" style="width: 150px">
              <tp-input-price
                custom-class="text-body-1 mt-0 pt-0"
                dense
                disabled
                flat
                hide-details
                outlined
                placeholder="Nhập giá"
                solo
                :value="lastPrice"
              ></tp-input-price>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="8" xl="9">
          <div class="font-weight-bold mb-2">
            Số lượng
            <span class="font-weight-light font-italic">
              (Tổng phân bổ
              <span class="font-weight-bold">{{
                goodsReceiptOption.po_option_branch.product_qty
              }}</span
              >, còn lại
              <span class="font-weight-bold">{{
                goodsReceiptOption.po_option_branch.product_qty_left
              }}</span
              >)
            </span>
          </div>
          <div class="d-flex align-center">
            <div class="mr-3">
              {{ goodsReceiptOption.serials.length }} /
              {{ goodsReceiptOption.po_option_branch.product_qty_left }}
            </div>
            <div>
              Trị giá hàng:
              <span class="font-weight-medium red--text text--accent-2">
                {{
                  (lastPrice * goodsReceiptOption.serials.length)
                    | formatCurrency
                }}
              </span>
              <span class="px-3">|</span>
              <span
                >Giá hóa đơn
                <span class="font-weight-medium red--text text--accent-2">
                  {{
                    (goodsReceiptOption.po_option_branch.option.import_price *
                      goodsReceiptOption.serials.length)
                      | formatCurrency
                  }}
                </span>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3">
        <div class="d-flex justify-space-between">
          <div class="font-weight-bold mb-2">Serials</div>

          <v-btn
            v-if="
              !(goodsReceipt.status === 1 || goodsReceipt.status === -1) &&
                goodsReceiptOption.serials.length > 0
            "
            color="red lighten-4 red--text"
            depressed
            x-small
            @click="goodsReceiptOption.serials = []"
          >
            Xóa tất cả
          </v-btn>
        </div>

        <v-combobox
          v-model="goodsReceiptOption.serials"
          :search-input.sync="comboboxInput"
          class="tp-combobox text-body-1"
          clearable
          :delimiters="[' ']"
          dense
          deletable-chips
          :disabled="goodsReceipt.status === 1 || goodsReceipt.status === -1"
          flat
          hide-details
          hide-selected
          placeholder="Nhập serial"
          multiple
          outlined
          solo
          single-line
          small-chips
          @update:search-input="handleSearchInput"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-bind="attrs"
              class="px-2"
              color="cyan lighten-5"
              :input-value="selected"
              label
              small
            >
              <span class="font-weight-medium pr-1">
                {{ item }}
              </span>
              <v-icon
                color="cyan lighten-3"
                small
                @click="parent.selectItem(item)"
              >
                mdi-close
              </v-icon>
            </v-chip>
          </template>
        </v-combobox>
      </div>
    </v-card>

    <v-card class="px-5 pt-4 pb-5 mt-5" flat>
      <list-gifts></list-gifts>
    </v-card>
  </div>
</template>

<script>
import ListGifts from "./components/ListGifts";
import ListRebates from "./components/ListRebates";

export default {
  components: {
    ListGifts,
    ListRebates
  },
  data() {
    return {
      comboboxInput: ""
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    goodsReceiptOption() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOption"];
    },
    lastPrice() {
      return (
        this.goodsReceiptOption.po_option_branch.option.import_price -
        this.rebateTotal
      );
    },
    rebateTotal() {
      const rebates = this.goodsReceiptOption.po_option_branch.option.rebates;

      if (rebates.length > 0) {
        let total = 0;

        rebates.forEach(item => {
          if (item.value) {
            total += parseInt(item.value);
          }
        });

        return total;
      } else {
        return 0;
      }
    }
  },

  methods: {
    // handleSearchInput() {
    //   if (this.comboboxInput && this.comboboxInput.split(" ").length > 1) {
    //     this.goodsReceiptOption.serials = this.goodsReceiptOption.serials
    //       .concat(
    //         this.comboboxInput
    //           .split(" ")
    //           .filter(term => !this.goodsReceiptOption.serials.includes(term))
    //       )
    //       .filter(Boolean);

    //     this.comboboxInput = "";
    //   }
    // }

    handleSearchInput() {
      const { comboboxInput, goodsReceiptOption } = this;

      if (!comboboxInput || comboboxInput.trim().split(" ").length <= 1) {
        return;
      }

      const serialsToAdd = comboboxInput
        .trim()
        .split(" ")
        .filter(term => !goodsReceiptOption.serials.includes(term));

      goodsReceiptOption.serials.push(...serialsToAdd);

      this.comboboxInput = "";
    }
  }
};
</script>
