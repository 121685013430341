<template>
  <v-card class="grey lighten-4 px-4 pt-3 pb-1" flat>
    <div class="gift-item d-flex align-center">
      <div class="gift-item--left mr-3">
        <div class="counter white rounded text-center font-weight-medium">
          {{ gift.serials.length }}
          <span class="text-subtitle-2"
            >/ {{ gift.po_option_gift_branch.gift_qty_left }}</span
          >
        </div>
      </div>

      <div
        class="gift-item--right"
        :title="
          `
          ${gift.po_option_gift_branch.gift.option.product_name} - ${gift.po_option_gift_branch.gift.option.SKU}
        `
        "
      >
        <div class="gift-text font-weight-medium mb-1">
          {{ gift.po_option_gift_branch.gift.option.product_name }}
        </div>
        <div class="gift-text text-subtitle-2 font-weight-light">
          <span>SKU: {{ gift.po_option_gift_branch.gift.option.SKU }}</span>
        </div>
      </div>
    </div>

    <div class="gift-serials mt-3">
      <v-combobox
        v-model="gift.serials"
        class="tp-combobox text-body-1"
        dense
        deletable-chips
        :disabled="goodsReceipt.status === 1 || goodsReceipt.status === -1"
        flat
        hide-selected
        :hint="
          `
          Tổng phân bổ ${gift.po_option_gift_branch.gift_qty},
          còn lại ${gift.po_option_gift_branch.gift_qty_left}
        `
        "
        persistent-hint
        placeholder="Nhập serial"
        multiple
        solo
        small-chips
        @change="gift.serials = $event"
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-bind="attrs"
            class="px-2"
            color="cyan lighten-5"
            :input-value="selected"
            label
            small
          >
            <span class="font-weight-medium pr-1">
              {{ item }}
            </span>
            <v-icon
              color="cyan lighten-3"
              small
              @click="parent.selectItem(item)"
            >
              mdi-close
            </v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    gift: {
      type: Object
    }
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-custom {
  ::v-deep .v-badge__wrapper {
    z-index: 9;
  }
}
.btn-gift--remove {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.gift-item {
  &--left {
    .counter {
      font-size: 16px;
      min-width: 50px;
      padding: 0.85rem;
    }
  }
  &--right {
    flex: 1;
    min-width: 0;
    .gift-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
