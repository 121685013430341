<template>
  <div class="px-5">
    <div class="top-bar">
      <v-tabs
        v-model="activeTab"
        background-color="white"
        class="rounded mb-5"
        slider-size="0"
        height="40px"
      >
        <v-tab
          class="tab-custom text-body-1 font-weight-medium text-none"
          active-class="primary--text"
          style="letter-spacing: 0"
          v-for="item in tabItems"
          :key="item.id"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <div
        v-if="
          goodsReceipt &&
            goodsReceipt.status !== null &&
            goodsReceipt.status !== undefined
        "
        class="top-bar--status"
      >
        <v-chip
          class="font-weight-bold"
          :color="
            goodsReceipt.status === 1
              ? 'green'
              : goodsReceipt.status === -1
              ? 'red accent-2'
              : 'grey'
          "
          small
          outlined
        >
          {{
            goodsReceipt.status === 1
              ? "Đã nhập"
              : goodsReceipt.status === -1
              ? "Bị hủy"
              : "Nháp"
          }}
        </v-chip>
      </div>
    </div>

    <v-tabs-items class="grey lighten-3" v-model="activeTab">
      <v-tab-item class="tab-item" key="info">
        <tab-info></tab-info>
      </v-tab-item>

      <v-tab-item class="tab-item" key="participants">
        <tab-participants></tab-participants>
      </v-tab-item>

      <v-tab-item class="tab-item" key="discussion">
        <tab-discussion></tab-discussion>
      </v-tab-item>

      <v-tab-item class="tab-item" key="history">
        <tab-payment-history></tab-payment-history>
      </v-tab-item>
    </v-tabs-items>
    <modal-goods-receipt-note-product-option></modal-goods-receipt-note-product-option>
  </div>
</template>

<script>
import ModalGoodsReceiptNoteProductOption from "./components/modals/ModalGoodsReceiptNoteProductOptions/index";
import TabDiscussion from "./components/TabDiscussion";
import TabInfo from "./components/TabInfo/index";
import TabPaymentHistory from "./components/TabPaymentHistory";
import TabParticipants from "./components/TabParticipants";

export default {
  components: {
    ModalGoodsReceiptNoteProductOption,
    TabDiscussion,
    TabInfo,
    TabParticipants,
    TabPaymentHistory
  },
  data() {
    return {
      activeTab: null,
      tabItems: [
        { id: "info", text: "Thông tin đơn hàng" },
        { id: "participants", text: "Phục vụ" },
        { id: "discussion", text: "Thảo luận" },
        { id: "payment-history", text: "Lịch sử thanh toán" }
      ]
    };
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    }
  },
  async created() {
    const route = this.$route;

    if (
      route.name === "transaction_goods-receipts_notes-create" &&
      route.query.id
    ) {
      await this.$store.dispatch(
        "GOODS_RECEIPT/getGoodsReceiptById",
        route.query.id
      );
    }
    if (route.name === "transaction_goods-receipts_notes-detail") {
      await this.$store.dispatch(
        "GOODS_RECEIPT/getGoodsReceiptById",
        route.params.goodsReceiptId
      );
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (
      this.$route.name === "transaction_goods-receipts_notes-create" &&
      to.name !== "transaction_goods-receipts_notes-detail" &&
      this.goodsReceipt.id
    ) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title:
            "Phiếu nhập sẽ không được lưu, bạn có chắc chắn muốn rời khỏi?",
          confirmBtn: {
            color: "red accent-2",
            text: "OK",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "GOODS_RECEIPT/forceDeleteGoodsReceipt",
                this.goodsReceipt.id
              );

              next();
            }
          },
          onCancelHandler: () => {
            next(false);
          }
        }
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar {
  position: relative;
  &--status {
    position: absolute;
    right: 12px;
    top: 8px;
  }
}
.tab-custom {
  transition: all 0.4s ease;
  &:after {
    background-color: #ccc;
    content: "";
    height: 14px;
    position: absolute;
    right: 0;
    width: 1px;
  }
  &:before {
    border-radius: 4px;
  }
  &:last-child {
    &:after {
      width: 0;
    }
  }
}
</style>
