<template>
  <v-row no-gutters>
    <v-col cols="5">
      <div class="d-flex align-center font-weight-bold">
        <v-icon class="pr-3">mdi-file-outline</v-icon>
        <span>Tài liệu kèm</span>
      </div>
    </v-col>
    <v-col cols="7">
      <v-row class="mx-0">
        <v-col
          v-for="(item, index) in invoiceParticipant.docs"
          :key="index"
          class="px-1"
          cols="4"
        >
          <v-hover v-slot:default="{ hover }">
            <v-img
              :aspect-ratio="1"
              :src="item"
              class="grey darken-3 rounded"
              contain
            >
              <v-fade-transition>
                <v-row
                  v-if="hover"
                  align="center"
                  class="fill-height grey darken-4 ma-0"
                  justify="center"
                  style="opacity: 0.65"
                >
                  <v-btn color="white" icon @click="removeImage(item)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-fade-transition>
            </v-img>
          </v-hover>
        </v-col>
      </v-row>

      <input
        ref="file"
        accept="image/x-png ,image/jpeg"
        multiple
        style="display: none"
        type="file"
        @change="selectFiles()"
      />

      <v-btn
        :disabled="
          invoiceParticipantStatusRequest.value === 'loading-uploadImages'
        "
        :loading="
          invoiceParticipantStatusRequest.value === 'loading-uploadImages'
        "
        block
        depressed
        @click="$refs.file.click()"
      >
        <v-icon class="pr-3">mdi-upload</v-icon>
        <span>Upload tài liệu</span></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { showModalAlertError } from "@/core/composables";

export default {
  data() {
    return {
      files: []
    };
  },

  computed: {
    invoiceParticipant() {
      return this.$store.getters["INVOICE_PARTICIPANT/invoiceParticipant"];
    },

    invoiceParticipantStatusRequest() {
      return this.$store.getters["INVOICE_PARTICIPANT/statusRequest"];
    },

    uploadedImages() {
      return this.$store.getters["INVOICE_PARTICIPANT/uploadedImages"];
    }
  },

  methods: {
    removeImage(link) {
      this.invoiceParticipant.docs = this.invoiceParticipant.docs.filter(
        item => {
          return item !== link;
        }
      );
    },

    selectFiles() {
      this.files = this.$refs.file.files;

      this.uploadFiles();

      // reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    },

    async uploadFiles() {
      let formDatas = [];

      Array.from(this.files).forEach(f => {
        const formData = new FormData();

        formData.append("file", f);

        formDatas.push(formData);
      });

      await this.updateFormDataImages(formDatas);
    },

    async updateFormDataImages(val) {
      await this.$store.dispatch("INVOICE_PARTICIPANT/uploadImages", val);

      if (
        this.invoiceParticipantStatusRequest.value === "success-uploadImages"
      ) {
        console.log(this.invoiceParticipant);
        this.invoiceParticipant.docs = this.invoiceParticipant.docs.concat(
          this.uploadedImages
        );
      } else if (
        this.invoiceParticipantStatusRequest.value === "error-uploadImages"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên hình ảnh",
          message: this.invoiceParticipantStatusRequest.message
        });
      }
    }
  }
};
</script>
