<template>
  <v-card class="return-rebate--card grey lighten-4 px-3 pt-0 pb-2" flat>
    <v-hover v-slot:default="{ hover }">
      <div class="btn-close">
        <v-icon
          :color="hover ? 'grey darken-1' : 'grey lighten-1'"
          size="20px"
          @click="removeReturnRebate"
          >mdi-close</v-icon
        >
      </div>
    </v-hover>
    <v-row class="align-end px-1">
      <v-col class="py-1" cols="12" xl="4">
        <v-text-field
          v-model="returnRebateCode"
          class="text-body-1 mt-0 pt-0"
          :error="rebateStatusRequest.value === 'error-getRebateByCode'"
          :error-messages="rebateStatusRequest.message"
          hide-details="auto"
          placeholder="Nhập mã CKTM và ấn enter"
          @keypress.enter="getRebateByCode"
        ></v-text-field>
      </v-col>
      <v-col class="py-1" cols="12" lg="6" xl="4">
        <tp-input-price
          custom-class="text-body-1 mt-0 pt-0"
          hide-details
          placeholder="Giá trị"
          :value="returnRebate.value"
          @change="returnRebate.value = $event"
        ></tp-input-price>
      </v-col>
      <v-col class="py-1" cols="12" lg="6" xl="4">
        Còn lại: {{ returnRebate.left | formatCurrency }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    returnRebate: {
      type: Object
    },
    returnRebateIndex: {
      type: Number
    }
  },
  data() {
    return {
      returnRebateCode: null
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  watch: {
    returnRebate(val) {
      if (val.code) {
        this.returnRebateCode = val.code;
      }
    }
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    rebate() {
      return this.$store.getters["REBATE/rebate"];
    },
    rebateStatusRequest() {
      return this.$store.getters["REBATE/statusRequest"];
    }
  },
  created() {
    if (this.returnRebate && this.returnRebate.code) {
      this.returnRebateCode = this.returnRebate.code;
    }
  },
  methods: {
    async getRebateByCode() {
      await this.$store.dispatch(
        "REBATE/getRebateByCode",
        this.returnRebateCode
      );

      if (this.rebateStatusRequest.value === "success-getRebateByCode") {
        this.goodsReceipt.returned_rebates[this.returnRebateIndex] = {
          ...this.rebate,
          value: this.returnRebate.value
        };
        // Update goods receipt
        await this.$store.dispatch(
          "GOODS_RECEIPT/setGoodsReceipt",
          JSON.parse(JSON.stringify(this.goodsReceipt))
        );
      }
    },

    async removeReturnRebate() {
      this.goodsReceipt.returned_rebates.splice(this.returnRebateIndex, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.return-rebate--card {
  position: relative;
  .btn-close {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
</style>
