<template>
  <v-card class="card-wrap" flat>
    <v-data-table
      class="table-scroll tp-table__row-pointer datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="goodsReceiptOptions"
      item-key="id"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            @click="openModalPurchaseOrderProductOptions(item, index)"
          >
            <!-- Start: NO. -->
            <td>{{ index + 1 }}</td>
            <!-- End: NO. -->
            <!-- Start: Product -->
            <td>
              <div class="my-2">
                <div class="font-weight-bold">
                  {{ item.po_option_branch.option.option.product_name }}
                </div>
                <div class="text-subtitle-2 black--text font-weight-light">
                  <div class="my-1">
                    <span
                      >MPN:
                      <span class="font-weight-bold black--text">
                        {{
                          item.po_option_branch.option.option.MPN
                            ? item.po_option_branch.option.option.MPN
                            : "Không có"
                        }}
                      </span></span
                    >
                    <span class="px-2">|</span>
                    <span
                      >SKU:
                      <span class="font-weight-bold black--text">
                        {{ item.po_option_branch.option.option.SKU }}
                      </span></span
                    >
                  </div>
                  <div
                    class="grey--text text--darken-2 tag-p__mb-0 mb-1"
                    v-html="item.po_option_branch.option.option.name"
                  ></div>
                  <ul class="gift-list black--text font-weight-light pl-0">
                    <li
                      class="gift-list--item"
                      v-for="gift in item.po_option_branch.gifts"
                      :key="gift.id"
                    >
                      <v-icon class="mr-1 red--text text--accent-1" size="12px">
                        mdi-wallet-giftcard
                      </v-icon>
                      <span>{{
                        `${gift.gift_qty} ${gift.gift.option.product_name} - ${gift.gift.option.SKU}`
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <!-- End: Product -->
            <!-- Start: Product quantity -->
            <td>
              {{ item.po_option_branch.product_qty_left }}/{{
                item.po_option_branch.product_qty
              }}
            </td>
            <!-- End: Product quantity -->
            <!-- Start: Import quantity -->
            <td>{{ item.serials ? item.serials.length : 0 }}</td>
            <!-- End: Import quantity -->
            <!-- Start: Import price -->
            <td class="text-center">
              <div>
                {{ item.po_option_branch.option.import_price | formatCurrency }}
              </div>
              <div
                v-if="item.po_option_branch.option.has_vat"
                class="text-subtitle-2 font-weight-medium"
              >
                (VAT)
              </div>
            </td>
            <!-- End: Import price -->
            <!-- Start: Rebates -->
            <td>
              {{
                calculateRebateTotal(item.po_option_branch.option.rebates)
                  | formatCurrency
              }}
            </td>
            <!-- End: Rebates -->
            <td class="text-center">
              {{
                item.po_option_branch.option.distributed_import_fee
                  | formatCurrency
              }}
            </td>
            <!-- Start: Last price -->
            <td class="text-center">
              <div>
                {{ calculateLastPrice(item) | formatCurrency }}
              </div>
              <div class="text-subtitle-2 font-weight-medium green--text">
                {{ calculateProfit(item.po_option_branch.option) }}%
              </div>
            </td>
            <!-- End: Last price -->
            <!-- Start: Total -->
            <td>
              {{
                (calculateLastPrice(item) * item.serials.length)
                  | formatCurrency
              }}
            </td>
            <!-- End: Total -->
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="7">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Phân bổ còn lại",
          align: "start",
          sortable: false,
          value: "product_qty"
        },
        {
          text: "SL đã nhập",
          align: "start",
          sortable: false,
          value: "import_qty"
        },
        {
          text: "Giá nhập",
          align: "center",
          sortable: false,
          value: "price"
        },
        {
          text: "CKTM (₫/sp)",
          align: "center",
          sortable: false,
          value: "rebate"
        },
        {
          text: " Phân bố phí nhập hàng",
          align: "center",
          sortable: false,
          value: "rebate",
          width: "106px"
        },
        {
          text: "Giá cuối",
          align: "center",
          sortable: false,
          value: "last_price"
        },
        {
          text: "Thành tiền",
          align: "center",
          sortable: false,
          value: "total"
        }
      ],
      productSearchKey: null
    };
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    goodsReceiptOptions() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptOptions"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    calculateLastPrice(item) {
      return (
        item.po_option_branch.option.import_price -
        this.calculateRebateTotal(item.po_option_branch.option.rebates) +
        item.po_option_branch.option.distributed_import_fee
      );
    },
    calculateProfit(item) {
      const lastPrice =
        item.import_price - this.calculateRebateTotal(item.rebates);
      return Math.floor(((item.option.price - lastPrice) / lastPrice) * 100);
    },

    calculateRebateTotal(rebates) {
      let total = 0;

      rebates.forEach(item => {
        if (item.value) {
          total += item.value;
        }
      });

      return total;
    },

    async openModalPurchaseOrderProductOptions(item, index) {
      // Set option
      await this.$store.dispatch("GOODS_RECEIPT/setGoodsReceiptOption", item);
      // Set option index
      await this.$store.dispatch(
        "GOODS_RECEIPT/setGoodsReceiptOptionActiveIndex",
        index
      );
      // Open modal
      this.$modal.show({ name: "modal-goods-receipt-note-product-options" });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 40px - 20px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 40px - 20px - 24px);
  }
}
.table-scroll {
  max-height: calc(100vh - 64px - 53px - 40px - 20px - 16px - 24px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 40px - 20px - 16px - 24px);
  }
}
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
.gift-list {
  list-style: none;
}
</style>
