<template>
  <v-card class="px-5 pt-4 pb-5" flat>
    <general-info></general-info>
  </v-card>
</template>

<script>
import GeneralInfo from "./components/GeneralInfo";

export default {
  components: {
    GeneralInfo
  }
};
</script>
